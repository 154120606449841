.input-group {
  @apply flex flex-col my-2 first:mt-0 last:mb-0 only:my-0;
}

.input-group .input {
  @apply border-b border-brand-soft-white/25 text-xs rounded-sm w-full py-3 px-4 block text-brand-soft-white placeholder:text-brand-soft-white/50 placeholder:uppercase;
}

.input-group .radio {
  @apply flex;
}

.input-group .checkbox .label,
.input-group .radio .label {
  @apply ml-10 mb-0 text-xs not-italic font-normal leading-5 font-montserrat text-brand-soft-white normal-case tracking-normal;
}

.input-group .checkbox .label a,
.input-group .radio .label a {
  @apply text-semantic-link;
}

/* checkbox */
.input-group .checkbox {
  @apply flex relative items-start min-h-6;
}

.input-group .checkbox .checkmark {
  @apply absolute h-6 w-6 bg-transparent border border-brand-soft-white after:content-[""] after:absolute after:hidden pointer-events-none rounded-md;
}

.input-group .checkmark:after {
  @apply h-3.5 w-2 rotate-[35deg] border-r-2 border-b-2 left-[7px] top-0.5 border-white;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
}

/* When the checkbox is checked, add a blue background */
.input-group input:checked ~ .checkmark {
  @apply after:block;
}

.input-group .input[type='checkbox'] {
  @apply absolute opacity-0 cursor-pointer h-6 w-6;
}

.input-group .input[type='radio'] {
  @apply w-5 h-5 text-transparent border-brand-soft-white rounded;
}

.input-group .input[type='text'],
.input-group .input[type='tel'] {
  @apply bg-transparent outline-none font-montserrat
  text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile transition
  focus:bg-white focus:text-black focus:placeholder:text-black/50;
}

.input-group textarea {
  @apply text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile font-montserrat
  bg-transparent outline-none focus:bg-white focus:text-black focus:placeholder:text-black/50;
}

.input-group .label {
  @apply block mb-2 text-black text-sm not-italic font-semibold leading-normal tracking-title-s uppercase font-montserrat;
}

.input-group .multi-checkbox {
  @apply flex items-center;
}

/* Dropdown, Datepicker */
.input-group .dropdown,
.input-group .datepicker {
  @apply border-none p-0;
}

.input-group .dropdown .main-button,
.input-group .datepicker .main-button {
  @apply border-b border-brand-soft-white/25  bg-transparent duration-100 h-10;
}

.input-group .dropdown .popover-title,
.input-group .datepicker .popover-title {
  @apply text-brand-soft-white/75 font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase;
}

.input-group .dropdown .popover-title.placeholder,
.input-group .datepicker .popover-title.placeholder {
  @apply text-brand-soft-white/50;
}

.input-group .dropdown .main-button svg,
.input-group .datepicker .main-button svg {
  @apply fill-brand-soft-white;
}

.input-group .dropdown .content,
.input-group .datepicker .content {
  @apply border border-black/75 rounded-sm bg-background-card;
}

.input-group .dropdown.open .main-button,
.input-group .datepicker.open .main-button {
  @apply rounded-sm bg-brand-soft-white border-black/75;
}

.input-group .dropdown.open .popover-title,
.input-group .datepicker.open .popover-title {
  @apply text-black/90;
}

.input-group .dropdown.open .main-button svg,
.input-group .datepicker.open .main-button svg {
  @apply fill-black/75;
}

.input-group .dropdown.open .content,
.input-group .datepicker.open .content {
  @apply drop-shadow-lg;
}

.input-group .dropdown.open .option {
  @apply hover:bg-brand-green/10 text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile font-montserrat text-black uppercase;
}

/* End Dropdown */

.input-group.error .label {
  @apply text-error;
}

.input-group.error .input {
  @apply border-error;
}

.input-group.error .error-message {
  @apply text-error text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile font-montserrat;
}

.input-group .input.number-input {
  @apply flex;
}

.input-group .input.number-input input[type='number']::-webkit-outer-spin-button,
.input-group .input.number-input input[type='number']::-webkit-inner-spin-button {
  @apply m-0;
  -webkit-appearance: none;
}

.input-group .input.number-input input[type='number'] {
  @apply w-4/5 text-center border-[none] bg-transparent;
  -moz-appearance: textfield;
  outline: none;
}

.input-group .input.number-input input[type='number']:focus-visible {
  @apply border-[none];
  outline: none;
}

.input-group .input.number-input button {
  @apply border-[0.5px] border-black/75 rounded-full p-1 hover:bg-brand-green/10 disabled:hover:bg-transparent;
}

.input-group .input.number-input button svg {
  @apply w-4 h-4 stroke-black;
}

.input-group .input.datepicker {
  @apply p-0 border-0;
}

.input-group .radio-group-container {
  @apply flex gap-6 mt-2;
}

.input-group .radio-group {
  @apply flex items-center gap-2 font-montserrat text-base not-italic font-normal leading-6.5 -tracking-quote-author;
}

.input-group .radio-group,
.input-group .multi-checkbox {
  @apply cursor-pointer;
}

.input-group:has(input:disabled),
.input-group:has(input:disabled) .number-input > *,
.input-group:has(input:disabled) label:has(input:disabled),
button:disabled {
  @apply cursor-not-allowed;
}

.input-group .input.number-input input[type='number']:disabled {
  @apply bg-transparent;
}

.select {
  @apply px-4 py-2.5 bg-transparent text-white text-xs leading-5 tracking-[.96px] font-medium font-montserrat uppercase outline-none grow placeholder:text-white;
}

.input-common {
  @apply font-montserrat p-2 backdrop-blur-[13px] border-white/25 rounded-full border text-white text-xs leading-5 tracking-[.96px] uppercase outline-none;
  background: rgba(255, 255, 255, 0.02);
}

.input-common option {
  @apply rounded-full border text-black text-xs leading-5 tracking-[.96px] uppercase outline-none;
}

input[type='color'] {
  @apply p-0 backdrop-blur-[13px] border-white/25 rounded-full border text-white text-xs leading-5 tracking-[.96px] uppercase outline-none;
  background: rgba(255, 255, 255, 0.02);
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  @apply backdrop-blur-[13px] border-white/25 rounded-full border text-white text-xs leading-5 tracking-[.96px] uppercase outline-none;
  background: rgba(255, 255, 255, 0.02);
}

.input-group .recaptcha {
  @apply flex justify-center my-1;
}

.input-group.field-recaptcha {
  @apply my-14;
}

.isPopup .input-group.field-recaptcha {
  @apply my-2;
}

.input-group.field-recaptcha .error-message {
  @apply text-center;
}

.FormModule.general {
  @apply py-12 sm:py-[72px] lg:py-20;
}

.FormModule.general .checkboxes-fieldset {
  @apply mt-2 sm:mt-6 lg:mt-2;
}

.FormModule.general .btn.secondary {
  @apply border-brand-soft-white/25;
}

/* Contact us form*/
.FormModule.contact-us {
  @apply py-10 sm:pt-16 sm:pb-14 lg:py-20;
}

.FormModule.contact-us .form-container {
  @apply px-0;
}

.FormModule.contact-us .section-container {
  @apply items-start mb-10;
}

.FormModule.contact-us .section-container .text {
  @apply text-left;
}

.FormModule.contact-us .section-container .heading,
.FormModule.contact-us .form-container .form {
  @apply lg:w-1/2;
}

.FormModule.contact-us .input-group .input[type='text'],
.FormModule.contact-us .input-group .input[type='tel'],
.FormModule.contact-us .input-group textarea,
.FormModule.contact-us .input-group .dropdown .popover-title {
  @apply normal-case text-sm font-normal leading-6;
}

.FormModule.contact-us .input-group .input[type='text'],
.FormModule.contact-us .input-group textarea,
.FormModule.contact-us .input-group .dropdown .main-button {
  @apply p-[11px] h-auto rounded-sm;
}

.FormModule.contact-us .input-group .input[type='tel'] {
  @apply py-[11px];
}

.FormModule.contact-us .input-group .dropdown .main-button,
.FormModule.contact-us .input-group .input {
  @apply border border-black/25;
}

.FormModule.contact-us .input-group .dropdown .main-button svg {
  @apply fill-black/25;
}

.FormModule.contact-us .input-group .dropdown .popover-title.placeholder,
.FormModule.contact-us .input-group .input {
  @apply text-black/50 placeholder:text-black/50;
}

.FormModule.contact-us .input-group .dropdown .popover-title,
.FormModule.contact-us .input-group .label,
.FormModule.contact-us .input-group .input {
  @apply text-black;
}

.FormModule.contact-us .input-group .input[type='radio'] {
  @apply appearance-none w-5 h-5 p-1 bg-clip-content rounded-full border border-solid border-black !important;
}

.FormModule.contact-us .input-group .input[type='radio']:checked {
  @apply bg-black;
}

.FormModule.contact-us .input-group .checkbox .checkmark {
  @apply absolute h-6 w-6 bg-transparent border border-black;
}

.FormModule.contact-us .input-group .checkmark:after {
  @apply h-3.5 w-2 rotate-[35deg] border-r-2 border-b-2 left-[7px] top-0.5 border-black;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
}

.FormModule.contact-us .btn-container {
  @apply justify-start;
}

.FormModule.contact-us .btn-container .btn {
  @apply !px-16 hover:bg-white w-full sm:w-auto;
}
.FormModule.contact-us .page-container,
.FormModule.contact-us .fieldset {
  @apply !gap-6;
}

.FormModule.contact-us .recaptcha {
  @apply justify-start;
}

.FormModule.contact-us .field-recaptcha {
  @apply mb-8 mt-0 lg:mb-12 lg:mt-8;
}

.FormModule.contact-us .field-recaptcha .error-message {
  @apply text-left;
}
