@font-face {
  font-family: 'Didot';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/fonts/didot_lt_pro.woff2') format('woff2');
}

@font-face {
  font-family: 'Didot';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../public/fonts/didot_lt_pro-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../public/fonts/montserrat-extralight.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../public/fonts/montserrat-light.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/fonts/montserrat-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../public/fonts/montserrat-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../public/fonts/montserrat-semibold.woff2') format('woff2');
}

.font-montserrat {
  @apply antialiased;
}
