.main-heading {
  @apply text-[5rem] leading-[7.5rem] sm:text-[6.25rem] sm:leading-[9.375rem] lg:text-[7.5rem] lg:leading-[11.25rem];
}

.text-h1 {
  @apply font-didot text-[3rem] leading-[4rem] sm:text-[4rem] sm:leading-[4.5rem] lg:text-[4.5rem] lg:leading-[6rem];
}

.text-h2 {
  @apply font-didot uppercase text-title-l not-italic font-normal leading-10 -tracking-headline-m-mobile
          md:text-4xl md:leading-[44px] md:-tracking-headline-l-mobile
          lg:text-title-xl lg:leading-12 lg:-tracking-headline-s;
}

.text-h3 {
  @apply font-montserrat text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase
          md:text-xl md:leading-6 md:tracking-quote-reg-mobile;
}

.text-h4,
.text-h5,
.text-h6,
.text-p {
  @apply font-montserrat text-sm not-italic font-semibold leading-normal tracking-title-s uppercase;
}

.text-subheading {
  @apply text-[0.875rem] leading-[1.25rem] lg:text-[1rem] lg:leading-[1.5rem] font-semibold tracking-[0.01em];
}
