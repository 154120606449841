.btn {
  @apply h-13 cursor-pointer font-montserrat text-sm not-italic font-medium leading-5 tracking-button-reg uppercase rounded-sm py-3.5 px-6 inline-flex justify-center items-center duration-200 [&_svg]:duration-200;
}

.small {
  @apply h-11 py-3 px-5 text-xs leading-4.5 tracking-quote-reg-mobile;
}

.circle {
  @apply rounded-full;
}

.btn svg {
  @apply first:mr-8 last:ml-8 only:mx-0;
}

.btn.small svg {
  @apply first:mr-6 last:ml-6 only:mx-0;
}

.btn.primary {
  @apply text-brand-black bg-transparent border duration-200 [&_svg]:stroke-brand-black 
  hover:text-brand-soft-white hover:bg-brand-green hover:border-brand-green [&_svg]:hover:stroke-brand-soft-white 
  active:bg-background-spotlight
  disabled:text-black/25 disabled:bg-transparent disabled:border-black/25 [&_svg]:disabled:stroke-black/25;
}

.btn.primary-dark {
  @apply text-brand-soft-white bg-background-spotlight duration-200 [&_svg]:stroke-brand-soft-white
  hover:text-brand-soft-white hover:bg-brand-green hover:border-brand-green [&_svg]:hover:stroke-brand-soft-white 
  active:bg-background-spotlight
  disabled:text-black/25 disabled:bg-transparent disabled:border-black/25 [&_svg]:disabled:stroke-black/25;
}

.btn.primary-light {
  @apply text-brand-soft-white bg-transparent border duration-200 [&_svg]:stroke-brand-soft-white
  hover:text-brand-soft-white hover:bg-brand-green hover:border-brand-green [&_svg]:hover:stroke-brand-soft-white 
  active:bg-background-spotlight
  disabled:text-black/25 disabled:bg-transparent disabled:border-black/25 [&_svg]:disabled:stroke-black/25;
}

/* secondary button */
.btn.secondary {
  @apply text-brand-soft-white bg-transparent border border-brand-soft-white [&_svg]:stroke-brand-soft-white
  hover:text-black hover:bg-brand-soft-white  hover:border-brand-soft-white [&_svg]:hover:stroke-black  
  disabled:bg-transparent disabled:text-brand-soft-white/25 disabled:border-brand-soft-white/25 [&_svg]:disabled:stroke-brand-soft-white/25;
}

.btn.secondary-reverse {
  @apply text-black bg-brand-soft-white border border-brand-soft-white [&_svg]:stroke-black
  hover:text-brand-soft-white hover:bg-brand-green  hover:border-brand-green [&_svg]:hover:stroke-brand-soft-white  
  disabled:bg-brand-soft-white disabled:border-0 disabled:opacity-25 disabled:text-brand-soft-white/25 disabled:border-brand-soft-white/25 [&_svg]:disabled:stroke-brand-soft-white/25;
}

/* text button */
.btn.text {
  @apply duration-200 [&_svg]:stroke-black 
  hover:bg-grey6 
  active:bg-grey3 active:text-white 
  disabled:opacity-25;
}

.btn.text-light {
  @apply duration-200 [&_svg]:stroke-white 
  hover:bg-grey6 
  active:bg-grey3 active:text-white 
  disabled:opacity-25;
}

/* link button */
.btn.link {
  @apply relative px-0 pb-0 duration-200 [&_svg]:stroke-black [&_svg]:duration-200
  hover:text-grey3 [&_svg]:hover:stroke-grey3 
  active:opacity-25 
  disabled:opacity-25;
}

.btn.link::after {
  @apply absolute content-[''] w-full h-px bottom-px left-0 bg-black hover:bg-grey3;
}

.btn.link.dark::after {
  @apply absolute content-[''] w-full h-px bottom-px left-0 bg-brand-soft-white hover:bg-grey3;
}

.btn.link:hover::after {
  @apply bg-grey3 duration-200;
}

.btn.link:active::after {
  @apply opacity-25 duration-200;
}

.btn.link:hover svg {
  @apply stroke-grey3 duration-200;
}

/* action buttons */
/* circle */
.btn.primary.circle {
  @apply hover:bg-grey3 active:opacity-10 active:border-none;
}

.btn.secondary.circle {
  @apply hover:bg-black 
  active:bg-white active:border-grey4 
  disabled:hover:bg-white disabled:hover:border-black;
}

.btn.secondary.circle:hover svg {
  @apply stroke-white;
}

.btn.secondary.circle:hover:disabled svg {
  @apply stroke-black;
}

.btn.secondary.circle:active svg {
  @apply stroke-grey4;
}

/* square */

/* image cta */
.btn.imageCta {
  @apply duration-200 [&_svg]:stroke-black h-8
  hover:text-brand-soft-white hover:bg-brand-green hover:border-brand-green [&_svg]:hover:stroke-brand-soft-white 
  active:bg-background-spotlight
  disabled:text-black/25 disabled:bg-transparent disabled:border-black/25 [&_svg]:disabled:stroke-black/25;
}
