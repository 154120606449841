/* Datepicker */

.react-datepicker {
  @apply p-4 w-full flex relative;
}

.react-datepicker__header {
  @apply text-center relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply text-black font-montserrat text-sm not-italic font-normal leading-6;
}

.react-datepicker__year-month-select {
  @apply text-black font-montserrat text-sm not-italic font-normal leading-6 flex gap-1;
}

.react-datepicker__year-month-select select {
  @apply border-collapse bg-background-card outline-none;
}

.react-datepicker__month-container {
  @apply w-full;
}

.react-datepicker__month {
  @apply flex flex-col gap-2.5;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply flex gap-2.5 whitespace-nowrap justify-between;
}

.react-datepicker__day-names {
  @apply mt-4 mb-3;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply text-black flex justify-center items-center w-8 lg:w-full aspect-square font-montserrat text-xs not-italic font-normal leading-5 uppercase;
}

.react-datepicker__day-name {
  @apply font-medium;
}

.react-datepicker__day--outside-month {
  @apply text-black/25;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  @apply cursor-pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply bg-black/20;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  @apply bg-grey5 text-black;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  @apply bg-brand-green/10;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  @apply bg-black/20;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  @apply cursor-default text-black/25;
}

.react-datepicker__day--disabled.react-datepicker__day--today,
.react-datepicker__day--today.react-datepicker__day {
  @apply bg-brand-green/5;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  @apply bg-transparent;
}

.react-datepicker__input-container {
  @apply rounded-sm w-full flex flex-row-reverse h-10 items-center
  px-4 py-3 border-b border-brand-soft-white/25;
}

.react-datepicker__aria-live {
  @apply absolute h-px overflow-hidden w-px whitespace-nowrap -m-px p-0 border-0;
  clip-path: circle(0);
}
